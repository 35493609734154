<template>
  <div class="row">
    <div class="col-12">
      <h5>Klient powinien wybrać 3 ulubione sklepy z listy dostępnych.</h5>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <template v-if="availableShops">
        <p>Wybierz lub wyszukaj sklepy.</p>

        <div class="input-group mb-3">
          <span class="input-group-text"><i class="icon-shop"></i></span>
          <v-select
              class="form-control"
              :options="availableShops"
              v-model="selectedShops"
              label="name"
              multiple
          ></v-select>
          <form-control-errors :error-bag="errors" field="shop_uuid"></form-control-errors>
        </div>

      </template>
      <template v-else>
        <p>Brak dostępnych sklepów do wyboru.</p>
      </template>
    </div>
  </div>

  <div class="row" v-if="selectedShops.length === 3">
    <div class="col-12 text-center">
      <button class="btn btn-primary" type="button" @click="saveFavoriteShops()">Zapisz wybrane sklepy</button>
    </div>
  </div>

</template>

<script>
import FormControlErrors from "@/components/FormControlErrors";
import vSelect from "vue-select";
import api from "@/api";

export default {
  name: "FavoriteShops",
  emits: [
    'shopsSaved',
  ],
  components: {
    FormControlErrors,
    vSelect

  },
  props: {
    promotionalEventUuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: {},
      availableShops: null,
      selectedShops: [],
    }
  },
  created() {
    this.getShops();
  },
  methods: {
    getShops() {
      api.getShops()
          .then(response => {
            this.availableShops = response.data;
          })
      ;
    },
    saveFavoriteShops() {
      const shopsUuids = this.selectedShops.map(function(shop) { return shop.uuid; });

      api.storeFavoriteShops(this.promotionalEventUuid, this.$store.state.currentUser.uuid, shopsUuids)
        .then(() => this.$emit('shopsSaved'));

    }
  }
}
</script>

<style scoped>

</style>
