import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue'
import UserData from "../views/Register/UserData";
import CustomerService from "@/views/CustomerService";
import SelectCustomerIdentificationMethod
  from "@/views/CustomerService/CustomerIdentification/SelectCustomerIdentificationMethod";

import CustomerIdentification from "@/views/CustomerService/CustomerIdentification/CustomerIdentification";
import Agreements from "@/views/Register/Agreements";
import $store from '../store'
import CurrentUserInfo from "@/views/CustomerService/CurrentUserInfo";
import ReceiptList from "@/views/CustomerService/Receipt/ReceiptList";
import ReceiptScan from "@/views/CustomerService/Receipt/ReceiptScan";
import ReceiptCorrection from "@/views/CustomerService/Receipt/ReceiptCorrection";
import StaticContentPage from "@/views/StaticContentPage";
import RewardIssueReceiptCheck from "@/views/CustomerService/IssueReward/RewardIssueReceiptCheck";
import Signature from "@/views/CustomerService/IssueReward/Signature";
import EventParticipate from "@/views/PromotionalEvent/EventParticipate";
import EventRewardSelect from "@/views/EventRewardSelect";
import EventReward from "@/views/CustomerService/IssueReward/EventReward";
import RewardsForPoints from "@/views/CustomerService/RewardsForPoints/RewardsForPoints";
import Authorization from "@/views/Auth/Authorization";
import CardManage from "@/views/CustomerService/CardManage";
import SelectFavoriteShops from "@/views/CustomerService/IssueReward/SelectFavoriteShops";

const routes = [
  {
    path: '/authorization',
    name: 'Authorization',
    component: Authorization
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/register-new-user-data',
    name: 'RegisterNew',
    component: UserData,
    meta: {
      requiresNoCurrentUser: true
    }
  },
  {
    path: '/register-new-agreements',
    name: 'Agreements',
    component: Agreements,
    meta: {
      requiresNoCurrentUser: true
    }
  },
  {
    path: '/customer-service',
    name: 'CustomerService',
    component: CustomerService,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/select-customer-identification-method',
    name: 'SelectCustomerIdentificationMethod',
    component: SelectCustomerIdentificationMethod,
    meta: {
      requiresNoCurrentUser: true
    }
  },
  {
    path: '/customer-identification/:method',
    name: 'CustomerIdentification',
    component: CustomerIdentification,
    meta: {
      requiresNoCurrentUser: true
    }
  },
  {
    path: '/current-user-info',
    name: 'CurrentUserInfo',
    component: CurrentUserInfo,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/receipt-list',
    name: 'ReceiptList',
    component: ReceiptList,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/receipt-scan',
    name: 'ReceiptScan',
    component: ReceiptScan,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/receipt-correction/:uuid?',
    name: 'ReceiptCorrection',
    component: ReceiptCorrection,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/page/:uuid',
    name: 'StaticContentPage',
    component: StaticContentPage
  },
  {
    path: '/event-participate/:uuid',
    name: 'EventParticipate',
    component: EventParticipate,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/event-reward/:uuid',
    name: 'EventReward',
    component: EventReward,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/event-reward-select',
    name: 'EventRewardSelect',
    component: EventRewardSelect,
    meta: {
      requiresCurrentUser: true
    }
  },

  {
    path: '/reward-issue-receipt-check/:eventUuid/:issueUuid',
    name: 'RewardIssueReceiptCheck',
    component: RewardIssueReceiptCheck,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/reward-issue-signature/:eventUuid/:issueUuid',
    name: 'RewardIssueSignature',
    component: Signature,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/reward-issue-select-favorite-shops/:eventUuid/:issueUuid',
    name: 'SelectFavoriteShops',
    component: SelectFavoriteShops,
    meta: {
      requiresCurrentUser: true
    }
  },
  {
    path: '/rewards-for-points',
    name: 'RewardsForPointsList',
    component: RewardsForPoints,
    meta: {
      requiresCurrentUser: false
    }
  },
  {
    path: '/card-manage',
    name: 'CardManage',
    component: CardManage,
    meta: {
      requiresCurrentUser: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {

  if(to.name !== 'Authorization' && !$store.state.loggedIn) {
    next({name: 'Authorization'})
  } else {
    if((to.meta.requiresCurrentUser && !$store.state.currentUser) || (to.meta.requiresNoCurrentUser && $store.state.currentUser != null)) {
      next({ name: 'Home'})
    } else {
      next()
    }
  }

})

export default router
