<template>
  <div class="col-12 mb-4 pb-1 border-bottom border-dark receipt_container">
    <div class="row">
      <div class="col-3">
        <img :src="receipt.preview" alt="podgląd"/>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-6">
            <small class="text-uppercase">Wartość:</small><br/>
            <h4>{{ (receipt.total_value / 100).toFixed(2) }} zł</h4>
          </div>
          <div class="col-6">
            <small class="text-uppercase">Data dodania:</small><br/>
            <h4>{{ receipt.created_at }}</h4>
          </div>
          <div class="col-6">
            <small class="text-uppercase">Numer:</small><br/>
            <h4>{{ receipt.receipt_number }}</h4>
          </div>
          <div class="col-6">
            <small class="text-uppercase">NIP:</small><br/>
            <h4>{{ receipt.taxpayer_identification_number }}</h4>
          </div>
          <div class="col-6">
            <small class="text-uppercase">Sklep:</small><br/>
            <h4>{{ $store.getters.shopNameByUuid(receipt.shop_uuid) }}</h4>
          </div>
          <div class="col-6">
            <small class="text-uppercase">Status:</small><br/>
            <h4>{{ receipt.status }}</h4>
          </div>
          <div class="col-6">
            <small class="text-uppercase">Przypisany do wydarzenia:</small><br/>
            <h4>{{ receipt.promotional_event_name }}</h4>
          </div>
          <div class="col-6" v-if="receipt.duplicate_uuid !== null">
            <small class="text-uppercase">Duplikat:</small><br/>
            <span class="badge el-badge__content--danger">TAK</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="receipt.status === 'processing'">
      <div class="col-4">
        <router-link :to="'/receipt-correction/' + receipt.uuid" class="btn btn-dark btn-sm my-2" style="width: 100%">
          Edytuj
        </router-link>
      </div>
      <div class="col-4 offset-4">
        <button @click="deleteReceipt(receipt.uuid)" class="btn btn-danger btn-sm my-2" style="width: 100%">Usuń
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ReceiptItem",
  props: {
    receipt: {
      type: Object,
      required: true
    }
  },
  methods: {
    deleteReceipt(uuid) {
      this.$emit('deleteReceipt', uuid);
    }
  }
}
</script>

<style scoped lang="scss">
.receipt_container {
  img {
    max-width: 100%;
  }

  small {
    color: $gray-500;
    font-weight: 900;
    font-size: 12px;
  }

  h4 {
    font-size: 1rem;
  }
}

</style>
