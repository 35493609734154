<template>
  <div class="current_user_info">
    <view-header title="Wybór nagrody" @back="$router.push('/customer-service')"></view-header>

    <div class="container">
      <h3><strong>Wydarzenie:</strong> {{ promotionalEvent.name }}</h3>
      <span>Rodzaj wydarzenia: {{ promotionalEvent.settings.config.eventType }}<br></span>
      <span>Czas pozostały do końca rezerwacji: {{ timeRemaining }} ({{ rewardIssuingTimeEnd }})<br></span>
    </div>

    <div class="container mt-4">
      <div class="row">
        <div class="col">
          <ul class="nav nav-tabs row" id="reward_tabs_nav">
            <li class="nav-item text-center col">
              <button class="nav-link active reward-issues-tab" data-bs-toggle="tab" data-bs-target="#reward_issues_tab" type="button">Nagrody zarezerwowane</button>
            </li>
            <li class="nav-item text-center col">
              <button class="nav-link rewards-tab" data-bs-toggle="tab" data-bs-target="#rewards_tab" type="button">Nagrody dostępne</button>
            </li>
            <li class="nav-item text-center col">
              <button class="nav-link collected-tab" data-bs-toggle="tab" data-bs-target="#collected_tab" type="button">Nagrody odebrane</button>
            </li>
          </ul>

          <div class="tab-content mt-5" id="reward_tabs_content">

            <div class="tab-pane fade show active" id="reward_issues_tab">
              <div class="row" v-if="availableRewardIssues.length > 0">
                <div class="col col-6 mb-4" v-for="rewardIssue in availableRewardIssues" :key="'reward_issue_'+rewardIssue.uuid">
                  <reward-tile :reward="rewardIssue.reward"
                               @click="rewardIssueCollect(rewardIssue.uuid)"
                               :hide_description="true"
                  ></reward-tile>
                </div>
              </div>
              <div v-else class="row">
                <div class="col">
                  <div class="alert alert-danger">Klient nie posiada zarezerwowanych nagród gotowych do odbioru</div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="rewards_tab">
              <div class="row" v-if="availableRewards.length > 0">
                <div class="col col-6 mb-4" v-for="reward in availableRewards" :key="'available_reward_'+reward.uuid">
                  <reward-tile :reward="reward" @click="selectedReward = reward.uuid"
                               :class="{ active: selectedReward === reward.uuid }"
                               :event-loyalty-points="eventLoyaltyPoints"
                  ></reward-tile>
                </div>
              </div>
              <div v-else>
                <div class="col">
                  <div class="alert alert-danger">Klient nie ma możliwości zarezerwowania i odebrania żadnej nagrody</div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="collected_tab">
              <div class="row" v-if="collectedRewardIssues.length > 0">
                <div class="col col-6 mb-4" v-for="rewardIssue in collectedRewardIssues" :key="'reward_issue_'+rewardIssue.uuid">
                  <reward-tile :reward="rewardIssue.reward"
                               :hide_description="true"
                  ></reward-tile>
                </div>
              </div>
              <div v-else class="row">
                <div class="col">
                  <div class="alert alert-danger">Klient nie posiada odebranych nagród</div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="mt-5">
        <div class="col-12">
          <input :class="{'is-invalid': errors.reward}" type="hidden" class="form-control"/>
          <form-control-errors :error-bag="errors" field="reward"></form-control-errors>
        </div>
      </div>

      <div class="row my-5">
        <div class="col-6 text-end">
          <router-link to="/customer-service" class="btn btn-light">Cofnij</router-link>
        </div>
        <div class="col-6">
          <button class="btn btn-primary" :disabled="!selectedReward" @click="handleRewardReservation">Dalej</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import RewardTile from "@/components/RewardTile";
import FormControlErrors from "@/components/FormControlErrors";
import api from '../../../api'

const bootstrap = require('bootstrap')

export default {
  name: "EventReward",
  components: {
    RewardTile,
    ViewHeader,
    FormControlErrors
  },
  data() {
    return {
      availableRewards: [],
      availableRewardIssues: [],
      collectedRewardIssues: [],
      selectedReward: null,
      errors: {},
    }
  },
  computed: {
    promotionalEvent() {
      return this.$store.state.config.promotionalEvents.find(pe => pe.uuid === this.$route.params.uuid)
    },
    eventLoyaltyPoints() {
      return this.promotionalEvent.settings.config.eventType === 'loyalty_points';
    },
    rewardIssuingTimeEnd() {
      return this.promotionalEvent.settings.config.rewardIssuingConfig.rewardIssuingTime.end;
    },
    timeRemaining() {
      let now = new Date();

      const end = this.promotionalEvent.settings.config.rewardIssuingConfig.rewardIssuingTime.end;
      let dt2 = new Date(end);

      if(now >= dt2) {
        return '0h';
      }

      let diff =(dt2.getTime() - now.getTime()) / 1000;
      diff /= (60 * 60);

      return Math.abs(Math.round(diff)) + 'h';
    }
  },
  created() {
    api.getAvailableRewards(this.$store.state.currentUser.uuid, this.$route.params.uuid)
        .then(response => { this.availableRewards = response.data })
        .finally(() => {
          api.getRewardIssues(this.$store.state.currentUser.uuid, this.$route.params.uuid)
            .then(response => { this.availableRewardIssues = response.data });

          api.getCollectedRewardIssues(this.$store.state.currentUser.uuid, this.$route.params.uuid)
            .then(response => { this.collectedRewardIssues = response.data });

        });
  },
  methods: {
    rewardIssueCollect(uuid) {
      this.goToRewardIssueReceiptCheck(this.$route.params.uuid, uuid);
    },
    setActiveTab() {
      let tab = new bootstrap.Tab(this.$refs.tab_nav.children[0]);
      this.$nextTick(() => { tab.show() })
    },
    handleRewardReservation() {
      api.reserveReward(this.selectedReward, this.$store.state.currentUser.uuid, this.$route.params.uuid)
        .then(response => {
          this.goToRewardIssueReceiptCheck(this.$route.params.uuid, response.data.uuid);
        })
        .catch(error => {
          if(error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        })
    },
    goToRewardIssueReceiptCheck(eventUuid, issueUuid) {
      this.$router.push(`/reward-issue-receipt-check/${eventUuid}/${issueUuid}`)
    }
  }
}
</script>

<style scoped lang="scss">
.nav-tabs {
  border-bottom: none;
}

.nav-link {
  text-transform: uppercase;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 12px 20px;
  border: none;

  &.active {
    border-bottom: 3px solid $primary;
  }
}

.reward-issues-tab {
  color: $danger;

  &.active {
    color: $danger;
    border-bottom: 3px solid $danger;
  }
}

.rewards-tab {
  color: $primary;

  &.active {
    color: $primary;
    border-bottom: 3px solid $primary;
  }
}

.collected-tab {
  color: $success;

  &.active {
    color: $success;
    border-bottom: 3px solid $success;
  }
}

</style>
