<template>
  <div class="col-12">
    <div class="row">
      <div class="col">
        <span v-if="confirmEnabled == 'true' && (!$store.state.currentUser.personal_identity.email_verified_at && !$store.state.currentUser.personal_identity.phone_number_verified_at)">
          <div class="alert alert-danger" role="alert">
            Konto nie zostało potwierdzone!
          </div>
        </span>
        <span><strong>Imię:</strong> {{ $store.state.currentUser.personal_identity.first_name }}<br/></span>
        <span><strong>Nazwisko:</strong> {{ $store.state.currentUser.personal_identity.last_name }}<br/></span>
        <span><strong>Login:</strong> {{ $store.state.currentUser.personal_identity.username }}<br/></span>
        <span><strong>Adres email:</strong> {{ $store.state.currentUser.personal_identity.email }}<br/></span>
        <span><strong>Numer telefonu:</strong> {{ $store.state.currentUser.personal_identity.phone_number }}<br/></span>
        <span><strong>Kod pocztowy:</strong> {{ $store.state.currentUser.personal_identity.postal_code }}<br/></span>
        <span><strong>Data urodzenia:</strong> {{ $store.state.currentUser.personal_identity.birthday }}<br/></span>
        <span><strong>Płeć:</strong> {{ $store.state.currentUser.personal_identity.gender }}<br/></span>
        <span><strong>Data rejestracji:</strong> {{ $store.state.currentUser.created_at }}<br/></span>
        <span><strong>Numer karty:</strong> {{ $store.state.currentUser.card_number }}<br/></span>

        <button class="btn btn-dark btn-sm my-2" @click="onClick()">Edytuj dane klienta</button>
        <hr>

        <template v-if="$store.state.config.features.rewards_for_points">
          <h4>Punkty w akcji <i>Eko Przedsionek</i>: </h4>
          <span>Liczba zdobytych punktów: {{ $store.state.currentUser.points.total }}<br/></span>
          <span>Liczba wydanych punktów: {{ $store.state.currentUser.points.issued }}<br/></span>
          <span>Liczba dostępnych punktów: {{ $store.state.currentUser.points.available }}<br/></span>
          <hr>
        </template>

        <h4>Punkty lojalnościowe:</h4>
        <span>Liczba zdobytych punktów: {{ $store.state.currentUser.loyalty_points.total }}<br/></span>
        <span>Liczba wydanych punktów: {{ $store.state.currentUser.loyalty_points.issued }}<br/></span>
        <span>Liczba dostępnych punktów: {{ $store.state.currentUser.loyalty_points.available }}<br/></span>
        <hr>

      </div>
    </div>

    <div v-if="$store.state.currentUser.tags && $store.state.currentUser.tags.length > 0" class="row mt-3">
      <div class="col">
        <strong>Uwagi: </strong><br/>
        <span class="badge bg-danger" v-for="t in $store.state.currentUser.tags" :key="'tag_'+t.id">{{ t.name }}<br/></span>
      </div>
    </div>


    <!--   todo: this section is not used -->
    <div v-if="$store.state.currentUser.receipts && $store.state.currentUser.receipts.length > 0" class="row mt-3">
      <div class="col">
        <strong>Zarejestrowane paragony klienta: </strong>
        <table class="table table-bordered border-primary table-sm">
          <thead>
          <tr>
            <th>Sklep</th>
            <th>Wartość</th>
            <th>Data transakcji</th>
            <th>Numer paragonu</th>
            <th>NIP</th>
            <th>Data rejestracji</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="r in $store.state.currentUser.receipts" :key="r.uuid">
            <td>{{ $store.getters.shopNameByUuid(r.shop_uuid) }}</td>
            <td>{{ (r.total_value / 100).toFixed(2) }}</td>
            <td>{{ r.transaction_date }}</td>
            <td>{{ r.receipt_number }}</td>
            <td>{{ r.taxpayer_identification_number }}</td>
            <td>{{ new Date(r.created_at).toISOString().replace('T', ' ').replace('.000Z', '') }}</td>
            <td>
              <span v-if="r.status === 'accepted'" class="badge bg-success">Zaakceptowany</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CurrentUserData",
  data() {
    return {
      confirmEnabled: process.env.VUE_APP_SHOULD_CONFIRMED
    }
  },
  methods: {
    onClick() {
      this.$emit('enable:edit-mode');
    }
  }
}
</script>

<style scoped>

</style>
