<template>
  <div class="row mt-2" v-if="!loading">
    <div class="col">
      <h4>Odebrane nagrody:</h4>

      <div v-if="collectedRewardIssues.length">
        <table class="table table-bordered table-sm mt-2" >
          <thead>
          <tr>
            <th>Miniaturka</th>
            <th>Nazwa nagrody</th>
            <th>Status</th>
            <th>Nazwa wydarzenia</th>
            <th>Zaktualizowano</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="ri in collectedRewardIssues" :key="ri.uuid">
            <td><img :src="ri.thumbnail_url" alt="miniaturka" class="img-thumbnail"/></td>
            <td>{{ ri.reward_name }}</td>
            <td>{{ ri.status }}</td>
            <td>{{ ri.promotional_event_name }}</td>
            <td>{{ ri.updated_at }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-else>
        <div class="alert alert-danger">Klient nie posiada odebranych nagród</div>
      </div>

    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "RewardCollectedList",
  data() {
    return {
      loading: false,
      collectedRewardIssues: [],
    }
  },
  created() {
    this.loading = true;

    api.getCollectedRewards(this.$store.state.currentUser.uuid)
        .then(response => {
          this.collectedRewardIssues = response.data;
          this.loading = false;
        });
  }
}
</script>

<style scoped lang="scss">

.img-thumbnail {
  max-height: 75px;
}
</style>
