<template>
  <div class="col-12">
    <div class="row">
      <div class="col">
        <h4>Edycja danych klienta</h4>

        <div class="mt-5">

          <div class="row">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.first_name}"
                        v-model="first_name" type="text" name="first_name" placeholder="Imię"
                        :required="$store.state.config.customerRegisterConfig.fields.first_name.required" autocomplete="off"></el-input>
              <form-control-errors :error-bag="formErrors" field="first_name"></form-control-errors>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.last_name}"
                        v-model="last_name" type="text" name="last_name" placeholder="Nazwisko"
                        :required="$store.state.config.customerRegisterConfig.fields.last_name.required" autocomplete="off"></el-input>
              <form-control-errors :error-bag="formErrors" field="last_name"></form-control-errors>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.username}"
                        v-model="username" type="text" name="username" placeholder="Login"
                        :required="$store.state.config.customerRegisterConfig.fields.username.required" autocomplete="off"></el-input>
              <form-control-errors :error-bag="formErrors" field="username"></form-control-errors>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <el-input
                  :class="{'is-invalid': formErrors.email}"
                  v-model="email" type="email" placeholder="Adres e-mail"
                  :required="$store.state.config.customerRegisterConfig.fields.email.required" autocomplete="off">
              </el-input>
              <form-control-errors :error-bag="formErrors" field="email"></form-control-errors>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.phone_number}"
                        v-model="phone_number" type="text" name="phone_number" placeholder="Numer telefonu" inputmode="tel"
                        :required="$store.state.config.customerRegisterConfig.fields.phone_number.required" autocomplete="off">
              </el-input>
              <form-control-errors :error-bag="formErrors" field="phone_number"></form-control-errors>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <el-input
                  :class="{'is-invalid': formErrors.postal_code}"
                  placeholder="Kod pocztowy"
                  v-maska="'##-###'"
                  size="large"
                  inputmode="numeric"
                  :required="$store.state.config.customerRegisterConfig.fields.postal_code.required"
                  autocomplete="off"
                  v-model="postal_code">
              </el-input>
              <form-control-errors :error-bag="formErrors" field="postal_code"></form-control-errors>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <el-date-picker v-model="birthday"
                              style="width: 100%"
                              format="YYYY-MM-DD"
                              value-format="YYYY-MM-DD"
                              prefix-icon="icon-calendar"
                              :class="{'is-invalid': formErrors.birthday}"
                              :placeholder="'Data urodzenia' + ($store.state.config.customerRegisterConfig.fields.birthday.required ? ' *' : '')">
              </el-date-picker>
              <form-control-errors :error-bag="formErrors" field="birthday"></form-control-errors>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <el-select v-model="gender"
                         :class="{'is-invalid': formErrors.gender}"
                         size="large"
                         style="width: 100%"
                         :placeholder="'Płeć' + ($store.state.config.customerRegisterConfig.fields.gender.required ? ' *' : '')">
                <el-option label="Mężczyzna" value="male"></el-option>
                <el-option label="Kobieta" value="female"></el-option>
                <el-option v-if="!$store.state.config.customerRegisterConfig.fields.required" label="Nie odpowiadam" value=""></el-option>
              </el-select>
              <form-control-errors :error-bag="formErrors" field="gender"></form-control-errors>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col text-center">
              <button class="btn btn-primary" @click="updateUserData()">Zapisz</button>
              <button class="btn btn-outline-dark mx-2" @click="disableEditMode()">Anuluj</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import FormControlErrors from "@/components/FormControlErrors";
import api from "@/api";

export default {
  name: "CurrentUserDataForm",
  components: {
    FormControlErrors
  },
  data() {
    return {
      email: null,
      phone_number: null,
      first_name: null,
      last_name: null,
      postal_code: null,
      birthday: null,
      username: null,
      gender: null,
      formErrors: {},
    }
  },
  created() {
    this.fillForm();
  },
  methods: {
    fillForm() {
      this.email = this.$store.state.currentUser.personal_identity.email;
      this.phone_number = this.$store.state.currentUser.personal_identity.phone_number;
      this.first_name = this.$store.state.currentUser.personal_identity.first_name;
      this.last_name = this.$store.state.currentUser.personal_identity.last_name;
      this.postal_code = this.$store.state.currentUser.personal_identity.postal_code;
      this.username = this.$store.state.currentUser.personal_identity.username;
      this.birthday = this.$store.state.currentUser.personal_identity.birthday;
      this.gender = this.$store.state.currentUser.personal_identity.gender;
    },
    async updateUserData() {
      const uuid = this.$store.state.currentUser.uuid;

      const payload = {
        email: this.email,
        phone_number: this.phone_number,
        first_name: this.first_name,
        last_name: this.last_name,
        postal_code: this.postal_code,
        username: this.username,
        birthday: this.birthday,
        gender: this.gender,
      }

      try {
        const response = await api.validateBeforeUpdateUserById(uuid, payload);

        if(response.status === 200) {

          api.updateUserById(uuid, payload)
              .then(response => {
                this.$store.dispatch('setCurrentUser', response.data);
                this.disableEditMode();
              })
              .catch((error) => {
                if (error.response.status === 422) {
                  this.formErrors = error.response.data.errors
                }
              })
        }

      } catch (error) {
        if(error.response.status === 422) {
          this.formErrors = error.response.data.errors
        }
      }

    },
    disableEditMode() {
      this.$emit('disable:edit-mode');
    },
  }
}
</script>

<style scoped>

</style>
