<template>
  <div class="row">
      <el-table :data="rewardsForPoints" :empty-text="'Brak dostępnych nagród.'" style="width: 100%">
        <el-table-column prop="name" label="Nazwa" fit="true" />
        <el-table-column prop="points_needed" label="Wymagane punkty" width="140" />
        <el-table-column prop="amount" label="Dostępna ilość" width="120" />
        <el-table-column label="Akcja" width="220">
          <template #default="scope">
            <el-button v-if="scope.row.amount && scope.row.amount > 0" size="large" type="success" @click="selectReward(scope.row)">
              Wydawanie nagrody
            </el-button>
            <el-button v-if="!scope.row.amount || scope.row.amount <= 0" size="large" type="warning" disabled>
              Nagroda niedostępna
            </el-button>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "RewardsForPointsList",
  emits: [
    'onRewardSelected'
  ],
  data() {
    return {
      rewardsForPoints: []
    }
  },
  mounted() {
    if (!this.rewardsForPoints || this.rewardsForPoints.length === 0) {
      this.getRewardsForPoints()
    }
  },
  methods: {
    selectReward(rewardRowData) {
      this.$emit('onRewardSelected', rewardRowData);
    },
    getRewardsForPoints() {
      api.getRewardsForPoints().then(response => this.rewardsForPoints = response.data);

      if (this.rewardsForPoints) {
        for (let rewardForPointsIndex in this.rewardsForPoints) {
          this.rewardsForPoints[rewardForPointsIndex].amount -= this.rewardsForPoints[rewardForPointsIndex].rewards_collected_amount;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>