<template>
  <div class="authorization-by-password mt-5">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <p>Aby zalogować się do aplikacji, wpisz hasło.</p>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <div class="input-group">
            <input :type="showPassword ? 'text' : 'password' " class="form-control rounded" v-model="password">

            <span class="input-group-text" v-if="showPassword" @click="onShowPassword"><i class="icon-unlock"></i></span>
            <span class="input-group-text" v-else-if="!showPassword" @click="onShowPassword"><i class="icon-lock"></i></span>

            <button type="button" class="btn btn-outline-primary"
                    @click="login">Zaloguj się</button>
          </div>

          <div v-if="errorNotFound || errorPassword" class="mt-1">
            <span class="text-danger" v-if="errorPassword">Hasło jest nieprawidłowe.</span>
            <span class="text-danger" v-else-if="errorNotFound">Nie znaleziono pracownika obsługi z takim hasłem.</span>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import api from "../../api";

export default {
  name: 'AuthorizationByPassword',
  data() {
    return {
      password: null,
      showPassword: false,
      errorNotFound: false,
      errorPassword: false,
    }
  },
  methods: {
    login() {

      api.loginByPassword(this.password)
          .then(response => {
            this.$store.dispatch('setLoggedIn', response.data)
            this.$router.push('/')
          })
          .catch(error => {
            if(error.response.status === 422) {
              this.errorNotFound = false;
              this.errorPassword = true;
            }
            else if(error.response.status === 404) {
              this.errorNotFound = true;
              this.errorPassword = false;
            }

          })
    },
    onShowPassword() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>
