import { createStore } from 'vuex'
import config from '../app.config'
import api from '../api'

export default createStore({
  state: {
    loading: false,
    loadingInfo: null,
    config: config,
    currentUser: null,
    register: {
      userData: null,
      userAgreements: null,
    },
    receiptRegister: {
      availableShops: [],
      currentSessionReceipts: [],
    },
    loggedIn: false,
    loggedUser: null
  },
  getters: {
    customerIdentificationMethods: state => {
      let customerIdentificationMethods = [];
      //TODO: Refaktoryzacja tego i zrobienie po jakiejś pętli, bo aż boli jak się patrzy
      if(state.config.customer_identification.email) customerIdentificationMethods.push('email')
      if(state.config.customer_identification.phone_number) customerIdentificationMethods.push('phone_number')
      if(state.config.customer_identification.card_number) customerIdentificationMethods.push('card_number')
      if(state.config.customer_identification.external_identifier) customerIdentificationMethods.push('external_identifier')

      return customerIdentificationMethods
    },
    shopNameByUuid: state => uuid => {
      let shop = state.receiptRegister.availableShops.find(s => s.uuid === uuid)
      return shop ? shop.name : uuid;
    },
    featureAvailableCustomerService: (state, getters) => {
      return state.config.features.customer_service && getters.customerIdentificationMethods.length > 0
    },
    featureAvailableCustomerRegister: (state) => {
      return state.config.features.customer_register
    },
    staticPages: (state) => {
      return state.config.publicPages
    },
    currentUserDisplayName: (state) => {
      if(!state.currentUser) return null
      return state.currentUser.personal_identity.first_name + ' ' + state.currentUser.personal_identity.last_name
    }
  },
  mutations: {
    SET_CONFIG(state, additionalConfig) {

      state.config = {...config, ...additionalConfig}

      let shops = []
      additionalConfig.promotionalEvents.forEach(pe => {

        if(pe.settings.config.receiptConfig === undefined) {
          return;
        }

        let shopsData = pe.settings.config.receiptConfig.shops

        /*
         * Convert object to array
         */
        if(typeof shopsData === 'object') {
          const keys = Object.keys(shopsData);
          let shopsDataArray = [];

          for(let i = 0; i < keys.length; i++){
            shopsDataArray.push(shopsData[keys[i]])
          }
          shopsData = shopsDataArray
        }

        shops = [...shops, ...(shopsData || [])]
      })
      shops = shops.slice().sort(function(a, b){
        return (a.name > b.name) ? 1 : -1;
      });
      state.receiptRegister.availableShops = shops
    },
    LOADING(state, loading) {
      if(typeof loading === 'string') {
        state.loadingInfo = loading
        loading = true;
      }
      state.loading = loading
      state.loadingInfo = null
    },
    SET_USER(state, user) {
      state.loggedUser = user
      state.loggedIn = user === null ? false : true;
    },
    SET_REGISTER_AGREEMENTS(state, agreements) {
      state.register.availableAgreements = agreements
    },
    SET_REGISTER_USER_DATA(state, userData) {
      state.register.userData = userData
    },
    SET_REGISTER_USER_AGREEMENTS(state, agreements) {
      state.register.userAgreements = agreements
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user
    },
    ADD_STATIC_CONTENT_PAGE(state, page) {
      state.staticContentPages.push(page)
    },
    ADD_RECEIPT_TO_CURRENT_SESSION(state, receipt) {
      state.receiptRegister.currentSessionReceipts.push(receipt)
    },
    CLEAR_CURRENT_SESSION_RECEIPT(state) {
      state.receiptRegister.currentSessionReceipts = []
    },
    ADD_CARD_NUMBER_TO_CURRENT_SESSION(state, number) {
      state.currentUser.card_number = number;
    }
  },
  actions: {
    loadAppConfig({ commit }) {
      api.getAppConfig()
        .then(response => {
          commit('SET_CONFIG', response.data)
        })
    },
    setLoggedIn({ commit }, user) {
      commit('SET_USER', user)
    },
    setLoggedOut({ commit }) {
      commit('SET_USER', null)
    },
    addReceiptToCurrentSession({ commit }, receipt) {
      commit('ADD_RECEIPT_TO_CURRENT_SESSION', receipt)
    },
    addCardNumberToCurrentSession({ commit }, number) {
      commit('ADD_CARD_NUMBER_TO_CURRENT_SESSION', number)
    },
    clearRegisterData({ commit }) {
      commit('SET_REGISTER_USER_DATA', null)
      commit('SET_REGISTER_USER_AGREEMENTS', null)
    },
    setCurrentUser({ commit }, user) {
      commit('SET_CURRENT_USER', user)
    },
    clearCurrentUser({ commit }) {
      commit('SET_CURRENT_USER', null)
      commit('CLEAR_CURRENT_SESSION_RECEIPT')
    }
  },
  modules: {

  }
})
