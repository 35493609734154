<template>
  <div class="receipt_list">
    <view-header :title="title" @back="back"></view-header>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <el-steps :active="activeStep" finish-status="success" align-center>
            <el-step title="Wybierz nagrodę" />
            <el-step title="Wyszukaj klienta" />
            <el-step title="Potwierdzenie" />
            <el-step title="Wydanie nagrody" />
          </el-steps>
        </div>

        <div class="col-12 mt-5"></div>

        <div class="col-12 mt-5">
          <rewards-for-points-list
              v-if="step === 1"
              @onRewardSelected="setSelectedReward($event)"
          />
          <find-customers-for-reward
              v-if="step === 2"
              :reward="selectedReward"
              @onClientSelect="setSelectedClient($event)"
          />
          <confirm-reward-for-user
              v-if="step === 3"
              :reward="selectedReward"
              :client="selectedClient"
              @onConfirmReward="setRewardIssue($event)"
              @onDiscardReward="back"
          />
          <issue-reward-for-user
              v-if="step === 4"
              :reward="selectedReward"
              :client="selectedClient"
              :reward-issue="rewardIssue"
              @onAcceptIssue="clearAll"
              @onRejectIssue="back"
          />
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import FindCustomersForReward from "@/views/CustomerService/RewardsForPoints/FindCustomersForReward";
import RewardsForPointsList from "@/views/CustomerService/RewardsForPoints/RewardsForPointsList";
import ConfirmRewardForUser from "@/views/CustomerService/RewardsForPoints/ConfirmRewardForUser";
import IssueRewardForUser from "@/views/CustomerService/RewardsForPoints/IssueRewardForUser";

export default {
  name: "RewardsForPoints",
  components: {
    IssueRewardForUser,
    ConfirmRewardForUser,
    RewardsForPointsList,
    FindCustomersForReward,
    ViewHeader,
  },
  data() {
    return {
      step: 1,
      activeStep: 0,
      title: 'Nagrody za punkty',
      rewardsForPoints: [],
      selectedReward: null,
      selectedClient: null,
      rewardIssue: null
    }
  },
  watch: {
    step(currentStep) {
      this.activeStep = currentStep - 1;

      if (currentStep < 3) {
        this.selectedClient = null;
      }

      if (currentStep < 2) {
        this.selectedReward = null;
      }
    }
  },
  mounted() {
    this.step = 1;
  },
  methods: {
    back() {
      if (this.step > 1) {
        this.step -= 1;
      } else {
        this.$router.push('/');
      }
    },
    setSelectedReward(rewardRowData) {
      this.selectedReward = rewardRowData;
      this.step = 2;
    },
    setSelectedClient(clientRowData) {
      this.selectedClient = clientRowData;
      this.step = 3;
    },
    setRewardIssue(rewardIssueData) {
      this.rewardIssue = rewardIssueData;
      this.step = 4;
    },
    clearAll() {
      this.selectedClient = null;
      this.selectedReward = null;
      this.rewardIssue = null;
      this.step = 1;
      this.$router.push('/');
    }
  }
}
</script>

<style scoped lang="scss">
.receipt_container {
  img {
    max-width: 100%;
  }
  small {
    color: $gray-500;
    font-weight: 900;
    font-size: 12px;
  }
  h4 {
    font-size: 1rem;
  }
}
</style>