<template>
  <div class="signature">
    <view-header :title="title" @back="$router.push(backLink)"></view-header>

    <div v-if="!rewardIssue" class="container">
      <div class="row" v-if="enableAgreements">
        <div class="col-12">
          <agreement-set v-model:agreements="agreements" :available-agreements="$store.state.currentUser.receipt_scanner_agreements"></agreement-set>
        </div>
        <div class="col-12 text-center mt-5">
          <button class="btn btn-primary" @click="handleAgreementsAccept">Zapisz</button>
        </div>
      </div>
      <div class="row" v-if="enableSignature">
        <div class="col-12 text-center">
          <vue-signature-pad ref="signature" :width="600" :height="300" class="signature_pad"></vue-signature-pad>
        </div>
      </div>

      <div class="my-5">
        <div v-if="!signatureIsValid" class="row mb-4">
          <div class="col text-center">
            <span class="invalid-feedback">Podpis jest wymagany przy odbiorze nagrody.</span>
          </div>
        </div>

        <div class="row" v-if="enableSignature">
          <div class="col text-center">
            <button class="btn btn-primary" @click="saveSignature">Zapisz podpis</button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container">
      <div class="row">
        <div class="col-6 offset-3 text-center">
          <p>Protokół odbioru został zapisany prawidłowo. Nagroda może zostać wydana klientowi.</p>
          <reward-tile :reward="rewardIssue.reward" :hide_description="true"></reward-tile>
        </div>
      </div>

      <div class="row my-5">
        <div class="col text-center">
          <router-link to="/customer-service" class="btn btn-primary">Zakończ wydawanie nagrody</router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import VueSignaturePad from 'vue3-signature-pad'
import api from '../../../api'
import RewardTile from "@/components/RewardTile";
import AgreementSet from "@/components/AgreementSet";

export default {
  name: "Signature",
  components: {
    AgreementSet,
    RewardTile,
    ViewHeader,
    VueSignaturePad
  },
  data() {
    return {
      rewardIssue: null,
      agreements: null,
      userAgreements: null,
      signatureIsValid: true,
      enableSignature: false,
      enableAgreements: true
    }
  },
  computed: {
    title() {
      if(this.rewardIssue === null) {
        return 'Odbiór nagrody - podpis';
      }
      return 'Odbiór nagrody - podsumowanie';
    },
    backLink() {
      return `/reward-issue-receipt-check/${this.$route.params.eventUuid}/${this.$route.params.issueUuid}`;
    },
    promotionalEvent() {
      return this.$store.state.config.promotionalEvents.find(pe => pe.uuid === this.$route.params.eventUuid)
    },
    requireSign() {
      return this.promotionalEvent.settings.config.rewardIssuingConfig.requireSign;
    },
  },
  methods: {
    saveSignature() {
      let signature = this.$refs.signature.saveSignature();

      if(signature.isEmpty && this.requireSign) {
        console.error('ERROR_SIGNATURE_EMPTY');
        this.signatureIsValid = false;

      } else {
        this.signatureIsValid = true;

        api.collectReward(this.$route.params.issueUuid, signature.data)
          .then(response => { this.rewardIssue = response.data; })
      }
    },
    handleAgreementsAccept() {
      const that = this
      api.updateScannerAgreements(this.$store.state.currentUser.uuid, this.agreements).then(() => {
        that.enableSignature = true
        this.enableAgreements = false
      });
    },
  },
  mounted() {
    this.enableSignature = this.$store.state.currentUser.receipt_scanner_agreements.length === 0
    this.enableAgreements = this.$store.state.currentUser.receipt_scanner_agreements.length > 0
  }
}
</script>

<style scoped lang="scss">
.signature_pad {
  margin: 0 auto;
  border: 1px solid $primary
}
.invalid-feedback {
  display: block;
}
</style>
