export default {
    api_url: process.env.VUE_APP_API_URL,
    customer_identification: {
        email: true,
        phone_number: true,
        card_number: false,
    },
    receipt_scanner: true,
    register_new_user_agreement_view_static_content_pages: [
        {
            name: 'Regulamin',
            uuid: '719b35c2-2c26-463d-b022-b2fc409c938d',
        }
    ]
}