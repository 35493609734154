<template>
  <div class="current_user_info">
    <view-header title="Weryfikacja danych klienta" @back="$router.push('/customer-service')"></view-header>

    <div class="container">
      <div class="row">
        <current-user-data></current-user-data>
      </div>

      <div class="row my-5">
        <div class="col text-center">
          <router-link :to="link" class="btn btn-primary">Dalej</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import CurrentUserData from "@/components/CurrentUserData";

export default {
  name: "RewardIssueReceiptCheck",
  components: {
    CurrentUserData,
    ViewHeader
  },
  computed: {
    promotionalEvent() {
      return this.$store.state.config.promotionalEvents.find(pe => pe.uuid === this.$route.params.eventUuid)
    },
    link() {
      if( this.promotionalEvent.settings.config.rewardIssuingConfig
          && this.promotionalEvent.settings.config.rewardIssuingConfig.favoriteShops === true
      ) {
        return `/reward-issue-select-favorite-shops/${this.$route.params.eventUuid}/${this.$route.params.issueUuid}`
      }

      return `/reward-issue-signature/${this.$route.params.eventUuid}/${this.$route.params.issueUuid}`;
    }
  }
}
</script>

<style scoped>

</style>
