<template>
  <div class="select_favorite_shops" v-if="!loading">
    <view-header title="Wybór ulubionych sklepów" @back="$router.push('/customer-service')"></view-header>

    <div class="container">
      <div class="row">
        <favorite-shops
            :promotional-event-uuid="this.$route.params.eventUuid"
            @shops-saved="gotoNextPage()"
        ></favorite-shops>
      </div>
    </div>

  </div>
  <div v-else>
    <view-header title="Sprawdzenie ulubionych sklepów"></view-header>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import FavoriteShops from "@/components/FavoriteShops";
import api from "@/api";

export default {
  name: "SelectFavoriteShops",
  components: {
    ViewHeader,
    FavoriteShops
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.checkFavoriteShops();
  },
  methods: {
    checkFavoriteShops() {
      this.loading = true;

      api.getFavoriteShops(this.$route.params.eventUuid, this.$store.state.currentUser.uuid)
        .then((response) => {
          if(response.data.shops_count === 3) {
            this.gotoNextPage();
          }

          this.loading = false;
        })
    },
    gotoNextPage() {
      this.$router.push(`/reward-issue-signature/${this.$route.params.eventUuid}/${this.$route.params.issueUuid}`)
    }
  }
}
</script>

<style scoped>

</style>
