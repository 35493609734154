<template>
  <div class="card_manage">
    <div class="container">
      <view-header title="Przypisywanie nowej karty" @back="$router.push('/customer-service')"></view-header>

      <div class="row">
        <div class="col">
          <span><strong>Numer karty:</strong> {{ $store.state.currentUser.card_number }}<br/></span>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">

          <div class="row" v-if="showForm">
            <div class="col-12">
              <input v-model="number" placeholder="Numer nowej karty" name="number" class="form-control" required autocomplete="off"/>
            </div>

            <div class="col-12 mt-3 text-center" v-if="errors">
              <form-control-errors :error-bag="errors" field="number" class="error-message"></form-control-errors>
            </div>

            <div class="col-12 mt-3 text-center">
              <button class="btn btn-primary" @click="assignCard">Zapisz</button>
            </div>
          </div>

          <div v-else >
            <div class="col-12 text-center">
              <span class="text-primary">Pomyślnie przypisano nowy numer.<br></span>
              <router-link to="/customer-service" class="btn btn-primary mt-2">Dalej</router-link>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import FormControlErrors from "@/components/FormControlErrors";
import api from '@/api'

export default {
  name: "CardManage",
  components: {
    ViewHeader,
    FormControlErrors
  },
  data() {
    return {
      number: null,
      errors: null,
      showForm: true,
    }
  },
  methods: {
    assignCard() {
      api.assignCard(this.$store.state.currentUser.uuid, this.number)
        .then(response => {
          const number = response.data.card_number;
          this.$store.dispatch('addCardNumberToCurrentSession', number);

          this.errors = null;
          this.showForm = false;
        })
        .catch(error => {
          if(error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        })
    }
  }
}
</script>

<style scoped>

.error-message {
  display: block;
}
</style>
