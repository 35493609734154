<template>
  <div class="receipt_list">
    <view-header title="Paragon" @back="back"></view-header>

    <div v-if="raw_receipt" class="container">
      <div class="row">
        <div class="col-12 mb-2" v-if="raw_receipt.duplicate_uuid !== null">
          <div class="alert alert-danger" role="alert">
            Paragon został oznaczony jako duplikat
          </div>
        </div>

        <div class="col-12 mb-3 text-center receipt_preview_container">
          <img :src="raw_receipt.preview" alt="podgląd paragonu"/>
        </div>

        <div class="col-12 mt-2" v-if="raw_receipt.promotional_event_uuid === null">
          <template v-if="availableEvents.length">
            <p>Przypisz paragon automatycznie do wydarzenia o typie Program Punktowy.</p>

            <div class="input-group mb-3">
              <span class="input-group-text"><i class="icon-event"></i></span>
              <v-select
                  class="form-control"
                  :options="availableEvents"
                  v-model="selectedEvent"
                  label="name"
              ></v-select>
              <form-control-errors :error-bag="errors" field="event_uuid"></form-control-errors>
            </div>
          </template>

          <template v-else>
            <p>Brak aktywnych wydarzeń o typie Program Punktowy.</p>
          </template>
        </div>
        <div class="col-12 mt-2" v-else>
          <p>Paragon jest przypisany do wydarzenia: <strong>{{ raw_receipt.promotional_event_name }}</strong></p>
        </div>

        <div class="col-12 mt-4">
          <template v-if="$store.state.receiptRegister.availableShops">
            <p>Wybierz lub wyszukaj najemcę.</p>

            <div class="input-group mb-3">
              <span class="input-group-text"><i class="icon-shop"></i></span>
              <v-select
                  class="form-control"
                  :options="$store.state.receiptRegister.availableShops"
                  v-model="selectedShop"
                  label="name"
              ></v-select>
              <form-control-errors :error-bag="errors" field="shop_uuid"></form-control-errors>
            </div>

          </template>
          <template v-else>
            <p>Brak dostępnych najemców do wyboru.</p>
          </template>
        </div>

        <div class="col-12">
          <div class="input-group mb-3">
            <span class="input-group-text"><i class="icon-receipt"></i></span>
            <input v-model="receipt.receipt_number"
                   :class="{'is-invalid': errors.receipt_number}"
                   type="text" class="form-control" placeholder="Numer paragonu"/>
            <form-control-errors :error-bag="errors" field="receipt_number"></form-control-errors>
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text"><i class="icon-nip"></i></span>
            <input v-model="receipt.taxpayer_identification_number"
                   :class="{'is-invalid': errors.taxpayer_identification_number}"
                   type="text" class="form-control" placeholder="NIP"/>
            <form-control-errors :error-bag="errors" field="taxpayer_identification_number"></form-control-errors>
          </div>
        </div>
        <div class="col-12">
          <div class="input-group mb-3">
            <span class="input-group-text"><i class="icon-calendar"></i></span>
            <input v-model="receipt.transaction_date"
                   :class="{'is-invalid': errors.transaction_date}"
                   type="date" class="form-control" placeholder="Data transakcji"/>
            <form-control-errors :error-bag="errors" field="transaction_date"></form-control-errors>
          </div>
        </div>
        <div class="col-12">
          <div class="input-group mb-3">
            <span class="input-group-text"><i class="icon-coins"></i></span>
            <input v-model="receipt.total_value_before_substraction"
                   :class="{'is-invalid': errors.total_value}"
                   @change="updateTotalValue"
                   type="number" min="0" step="0.01" class="form-control" placeholder="Wartość całkowita"/>
            <form-control-errors :error-bag="errors" field="total_value"></form-control-errors>
          </div>
        </div>
        <div v-if="receipt.total_value !== receipt.total_value_before_substraction" class="col-12">
          <p>Wartość paragonu po korekcie: <strong>{{ receipt.total_value }}</strong></p>
        </div>
        <div class="col-12">
          <input :class="{'is-invalid': errors.receipt}" type="hidden" class="form-control"/>
          <form-control-errors :error-bag="errors" field="receipt"></form-control-errors>
        </div>
      </div>

      <div class="row mt-5 receipt_value_substraction">
        <div class="col-12">
          <h6 class="text-center">Wyklucz (odejmij) z paragonu:</h6>
          <p>Wszystkie wartości produktów wykluczonych z programu, zostaną zsumowane i odjęte od kwoty rachunku.</p>
        </div>
        <div class="col-12">
          <receipt-substraction-set @substraction-sum-changed="updateSubstractions"></receipt-substraction-set>
        </div>
      </div>

      <div class="row mt-5 mb-5">
        <div class="col text-center">
          <button class="btn btn-primary" @click="handleReceiptConfirmation">Zatwierdź paragon</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import ReceiptSubstractionSet from "@/components/ReceiptSubstractionSet";
import api from '../../../api'
import FormControlErrors from "@/components/FormControlErrors";
import vSelect from "vue-select";
import {ElNotification} from "element-plus";

export default {
  name: "ReceiptCorrection",
  components: {
    FormControlErrors,
    ReceiptSubstractionSet,
    ViewHeader,
    vSelect
  },
  data() {
    return {
      receipt: {
        participation_update: false,
        promotional_event_uuid: null,
        uuid: null,
        shop_uuid: null,
        receipt_number: null,
        taxpayer_identification_number: null,
        sale_date: null,
        total_value_before_substraction: null,
        total_value: null,
        substractions: null,
      },
      raw_receipt: null,
      errors: {},
      selectedShop: null,
      selectedEvent: null
    }
  },
  computed: {
    availableEvents() {
      return this.$store.state.config.promotionalEvents.filter((promotionalEvent) => promotionalEvent.event_type === 'loyalty_points');
    }
  },
  watch: {
    selectedShop() {
      if(this.selectedShop) {
        this.receipt.shop_uuid = this.selectedShop.uuid;
      } else {
        this.receipt.shop_uuid = null;
      }
    },
    selectedEvent() {
      if(this.selectedEvent && this.raw_receipt.promotional_event_uuid === null) {
        this.receipt.promotional_event_uuid = this.selectedEvent.uuid;
      } else {
        this.receipt.promotional_event_uuid = null;
      }
    }
  },
  created() {
    this.loadReceiptRawData();
  },
  methods: {
    handleReceiptConfirmation() {
      let payload = {...this.receipt};

      payload.total_value = parseFloat(payload.total_value) * 100;

      api.updateReceipt(payload)
        .then(response => {
          this.$store.dispatch('addReceiptToCurrentSession', response.data.receipt);

          ElNotification.success({
            title: response.data.message,
            duration: 10000
          })

          this.$router.push('/receipt-list');
        })
        .catch(error => {
          ElNotification.warning({
            title: 'Zatwierdzenie paragonu nie powiodło się. Sprawdź błędy w formularzu.',
          })

          if(error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        })
    },
    back() {
      this.$router.push('/receipt-list')
    },
    updateSubstractions(val) {
      this.receipt.substractions = val
      this.updateTotalValue()
    },
    updateTotalValue() {
      this.receipt.total_value = (this.receipt.total_value_before_substraction - this.receipt.substractions).toFixed(2);
    },
    loadReceiptRawData() {
      api.getReceipt(this.$route.params.uuid)
        .then(response => {
          this.raw_receipt = response.data
          this.fillForm()
        })
    },
    fillForm() {
      this.receipt.uuid = this.raw_receipt.uuid;

      if(this.raw_receipt.promotional_event_uuid === null) {
        if(this.selectedEvent === undefined || this.selectedEvent === null) {
          if(this.availableEvents.length > 0) {
            this.selectedEvent = this.availableEvents[0];
          }
        }
      } else {
        this.receipt.participation_update = true;
        this.receipt.promotional_event_uuid = this.raw_receipt.promotional_event_uuid;
      }

      this.receipt.shop_uuid = this.raw_receipt.shop_uuid
      this.selectedShop = this.$store.state.receiptRegister.availableShops.find(s => s.uuid === this.receipt.shop_uuid);

      this.receipt.receipt_number = this.raw_receipt.receipt_number
      this.receipt.taxpayer_identification_number = this.raw_receipt.taxpayer_identification_number

      this.receipt.transaction_date = this.raw_receipt.transaction_date
      this.receipt.total_value_before_substraction = (this.raw_receipt.total_value / 100).toFixed(2)

      this.$nextTick(() => { this.updateTotalValue() })
    }
  }
}
</script>

<style scoped lang="scss">
.receipt_preview_container {
  img {
    max-width: 200px;
  }
}

.receipt_value_substraction {
  h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.2px;

    & + p {
      color: $gray-500;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.4px;
    }
  }
}

.input-group {
  .input-group-text {
    border-right-color: transparent;
    & + select,
    & + input {
      border-left-color: transparent;
    }
  }
  i {
    font-size: 24px;
  }
}

.invalid-feedback {
  display: block;
}

</style>
