<template>
  <div class="receipt_scan text-center">
    <web-cam :width="800" :height="1067" @cameras="setCamera" ref="webCam"></web-cam>
    <button class="btn btn-dark back_button" @click="back">Powrót</button>
    <button class="btn btn-light scan_button" @click="scan">Skanuj</button>
  </div>
</template>

<script>
import WebCam from '@/components/WebCam'
import api from '../../../api'

export default {
  name: "ReceiptScan",
  components: {
    WebCam
  },
  methods: {
    scan() {
      api.parseReceipt(this.$refs.webCam.capture(), this.$store.state.currentUser.uuid)
        .then(response => {
          if (response.data.error) {
            alert(response.data.error);
          } else {
            this.$router.push('/receipt-correction/' + response.data.uuid)
          }
        })
    },
    back() {
      this.$router.push('/receipt-list')
    },
    setCamera(e) {
      this.cameras = e
      let deviceId = this.cameras[this.cameras.length - 1].deviceId
      this.$refs.webCam.changeCamera(deviceId)
    },
  }
}
</script>

<style scoped lang="scss">
.receipt_scan {
  position: relative;
  background: black;
  height: 100vh;
  width: 100vw;

  .back_button {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .scan_button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>