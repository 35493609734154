<template>
  <div class="row">
    <div class="col-12 text-center">
      <el-button size="large" type="primary" @click="acceptIssueReward" :disabled="processing">
        ZREALIZUJ WYDANIE NAGRODY
      </el-button>
      <!--<el-button size="large" type="warning" @click="rejectIssueReward" :disabled="processing">
        ANULUJ I COFNIJ REZERWACJĘ
      </el-button>-->
    </div>
  </div>

  <el-dialog v-model="summaryDialogVisible" title="Informacja" width="80%">
    <span>
      Nagroda została wydana.
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" size="large" @click="closeConfirmationDialog()">OK</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import api from "@/api";

export default {
  name: "IssueRewardForUser",
  emits: [
    'onAcceptIssue',
    'onRejectIssue'
  ],
  data() {
    return {
      summaryDialogVisible: false,
      processing: false
    }
  },
  props: [
    'client',
    'reward',
    'rewardIssue'
  ],
  methods: {
    acceptIssueReward() {
      this.processing = true;

      api.collectRewardForPoints(this.rewardIssue.uuid).then(() => {
        this.summaryDialogVisible = true;
        this.processing = false;
      }, () => {
        this.processing = false;
      });
    },
    /*rejectIssueReward() {
      api.cancelReservationRewardForPoints(this.rewardIssue.uuid).then();
    },*/
    closeConfirmationDialog() {
      this.summaryDialogVisible = false
      this.$emit('onAcceptIssue');
    }
  }
}
</script>

<style scoped>

</style>