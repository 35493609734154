const preprocessor = {
    methods: {
        findMentions(text) {
            const regex = /\[.*?\]([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/gm
            let mentions = text.match(regex)
            return mentions ?? []
        },
        getMentionContent(mention) {
            return mention.slice(0, -37).substring(1)
        },
        getMentionSrc(mention) {
            return mention.substring(mention.length - 36)
        },
        __(text) {
            this.findMentions(text).forEach(m => {
                text = text.replace(m, '<a href="#/page/' + this.getMentionSrc(m) + '">' + this.getMentionContent(m) + '</a>')
            })
            return text;
        },
        _n(text) {
            this.findMentions(text).forEach(m => {
                text = text.replace(m, this.getMentionContent(m))
            })
            return text;
        }
    }
}

export {
    preprocessor,
}