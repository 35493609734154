<template>
  <div class="event_participate">
    <view-header :title="'Zgłoszenie uczestnictwa w wydarzeniu: ' + promotionalEvent.name" @back="$router.push('/customer-service')"></view-header>

    <div class="container">
      <div v-if="mode === 'agreements_check'" class="row">
        <div class="col-12">
          <agreement-set v-model:agreements="userAgreements" :available-agreements="promotionalEvent.required_agreements" :errors="formErrors"></agreement-set>
        </div>
        <div class="col-12 text-center mt-5">
          <button class="btn btn-primary" @click="handleAgreementsAccept">Zapisz</button>
        </div>
      </div>

      <template v-else>

        <div v-if="promotionalEvent.settings.config.receiptConfig" class="row">
          <div class="col-12 mb-5">
            <p>
              Wybierz maksymalnie {{ promotionalEvent.settings.config.receiptConfig.maxNumberOfReceiptsPerRegistration }}
              paragony na kwotę minimum {{ (promotionalEvent.settings.config.receiptConfig.minTotalValue / 100).toFixed(2) }} zł,
              aby wziąć udział w wydarzeniu.
            </p>
            <div v-for="(r,index) in receipts" :key="'r_'+index" class="p-2 mb-2 border border-2"
                 :class="{'border-success': selectedReceipts.indexOf(r.uuid) !== -1, 'border-dark': selectedReceipts.indexOf(r.uuid) === -1}"
                 @click="toggleReceipt(r.uuid)">
              <small>{{ (r.total_value / 100).toFixed(2) }} zł</small><br/>
              <small>{{ r.receipt_number }}</small><br/>
              <small>{{ r.transaction_date }}</small><br/>
              <small>{{ $store.getters.shopNameByUuid(r.shop_uuid) }}</small><br/>
            </div>
          </div>
        </div>

        <div class="row" v-if="errors">
          <div class="col-12">
            <span class="text-danger" v-for="(e,i) in errors.promotional_event" :key="'pe_error_'+i">{{ e }}<br/></span>
          </div>
        </div>

        <div class="row">
          <div v-if="$store.state.config.features.receipt_register" class="col my-3">
            <tile-button label="Rejestracja paragonu" icon="add-receipt" @click="receiptList"></tile-button>
          </div>
          <div class="col my-3">
            <tile-button-improved
                label="Potwierdź rejestrację w wydarzeniu" icon="plus"
                @tile-button-clicked="handleParticipation"
            ></tile-button-improved>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import AgreementSet from "@/components/AgreementSet";
import api from "@/api";
import TileButton from "@/components/TileButton";
import TileButtonImproved from "@/components/TileButtonImproved";

export default {
  name: "EventParticipate",
  components: {
    ViewHeader,
    AgreementSet,
    TileButton,
    TileButtonImproved,
  },
  data() {
    return {
      mode: null,
      userAgreements: null,
      formErrors: {},
      errors: {},
      receipts: [],
      selectedReceipts: []
    }
  },
  computed: {
    promotionalEvent() {
      return this.$store.state.config.promotionalEvents.find(pe => pe.uuid === this.$route.params.uuid)
    },
  },
  mounted() {
    this.validateParticipationRequirements();
    this.getReceipts();
  },
  methods: {
    validateParticipationRequirements() {
      api.validateEventParticipationRequirements(this.promotionalEvent.uuid, this.$store.state.currentUser.uuid)
        .then(() => {
          this.mode = null
        })
        .catch(error => {
          if(error.response.status === 422) {
            let errors = error.response.data.errors;

            if(errors.agreements) {
              this.mode = 'agreements_check';
            } else if(errors.promotional_event) {
              this.errors = errors;
            }
          }
        })
    },
    getReceipts() {
      if(this.promotionalEvent.settings.config.receiptConfig) {
        api.getReceipts(this.$store.state.currentUser.uuid, 'processing')
            .then(response => { this.receipts = response.data })
      }
    },
    handleParticipation() {
      api.storeEventParticipation(this.promotionalEvent.uuid, this.$store.state.currentUser.uuid, {
        receipts: this.selectedReceipts
      })
        .then(() => {
          this.$router.push('/event-reward/' + this.promotionalEvent.uuid)
        })
        .catch(error => {
          if(error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        })
    },
    handleAgreementsAccept() {
      api.updateCustomerAgreements(this.$store.state.currentUser.uuid, this.userAgreements);
    },
    toggleReceipt(uuid) {
      if(this.selectedReceipts.indexOf(uuid) === -1) this.selectedReceipts.push(uuid)
      else this.selectedReceipts = this.selectedReceipts.filter(r => r !== uuid)
    },
    receiptList() {
      this.$router.push('/receipt-list');
    }
  }
}
</script>

<style scoped>

</style>
