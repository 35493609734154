<template>
  <div v-html="$store.state.config.brand"></div>
</template>

<script>
export default {
name: "Brand"
}
</script>

<style scoped>

</style>