<template>
  <div class="row">
    <div class="col-12 text-center">
      <el-row :gutter="12">
        <el-col :span="12">
          <el-card shadow="always" class="box-card">
            <template #header>
                <h5>Dane klienta</h5>
            </template>

            <div class="text item text-left">
              Imię i nazwisko: <b>{{ client.first_name }} {{ client.last_name }}</b>
            </div>
            <div class="text item text-left">
              Telefon: <b>{{ client.phone_number }}</b>
            </div>
            <div class="text item text-left">
              E-mail: <b>{{ client.email }}</b>
            </div>

            <hr/>

            <div class="text item text-left">
              Zebrane punkty: <b>{{ client.customer_points }}</b>
            </div>
            <div class="text item text-left">
              Koszt nagrody: <b>{{ reward.points_needed }}</b>
            </div>
            <div class="text item text-left">
              Pozostanie: <b>{{ client.customer_points - reward.points_needed }}</b>
            </div>

          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="always" class="box-card">
            <template #header>
                <h5>Informacje o nagrodzie</h5>
            </template>

            <div class="text item text-left">
              <b>{{ reward.name }}</b>
            </div>
            <div class="text item text-left">
              {{ reward.description }}
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>

  <hr/>

  <div class="row">
    <div class="col-12 text-center">
      <el-button size="large" type="primary" @click="confirmReward" :disabled="confirming">
        AKCEPTUJ
      </el-button>
      <el-button size="large" type="danger" @click="discardReward" :disabled="confirming">
        ANULUJ
      </el-button>
    </div>
  </div>

  <el-dialog v-model="infoDialogVisible" title="Niepowodzenie" width="80%">
    <span class="error-message">{{ errorText }}</span>
    <template #footer>
      <span class="dialog-footer text-center">
        <el-button type="primary" size="large" @click="infoDialogVisible = false">Zamknij</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import api from "@/api";

export default {
  name: "ConfirmRewardForUser",
  emits: [
    'onConfirmReward',
    'onDiscardReward'
  ],
  props: [
    'client',
    'reward'
  ],
  data() {
    return {
      confirming: false,
      infoDialogVisible: false,
      errorText: null
    }
  },
  methods: {
    confirmReward() {
      this.confirming = true;

      api.reserveRewardForPoints(this.reward.uuid, this.client.uuid).then((response) => {
        this.confirming = false;
        this.$emit('onConfirmReward', response.data);
      }).catch((error) => {
        this.infoDialogVisible = true;
        this.confirming = false;

        switch (error.response.data) {
          case 'The customer already reserved or collected this reward':
            this.errorText = 'Ten klient już odebrał taką nagrodę.';
            break;

          case 'Too small amount in the reward':
            this.errorText = 'Nie ma już tej nagrody na stanie.';
            break;

          case 'The customer does not have enough points':
            this.errorText = 'Klient nie posiada wystarczającej liczby punktów aby zarezerwować tę nagrodę.';
            break;

          default:
            this.errorText = 'Wystąpił błąd przy sprawdzaniu rezerwacji wydawanej nagrody.'
        }
      });
    },
    discardReward() {
      this.$emit('onDiscardReward');
    }
  }
}
</script>

<style scoped>
  .text-left {
    text-align: left;
  }
  .error-message {
    font-size: 1.4em;
  }
</style>