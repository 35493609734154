<template>
  <div class="static_content_page">
    <view-header :title="title" @back="$router.back()"></view-header>

    <div class="container">
      <div class="row my-5">
        <div v-if="page" class="col" v-html="page.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";

export default {
  name: "StaticContentPage",
  components: {
    ViewHeader
  },
  computed: {
    title() {
      return this.page.title
    },
    page() {
      return this.$store.state.config.publicPages.find(p => p.uuid === this.$route.params.uuid) ||
          this.$store.state.config.customerRegisterConfig.register_agreements_pages.find(p => p.uuid === this.$route.params.uuid)
    }
  },
}
</script>

<style scoped>

</style>