<template>
  <div class="receipt_list">
    <view-header title="Paragony" @back="back"></view-header>

    <div class="container mt-5">
      <ul class="nav nav-justified nav-tabs">
        <li class="nav-item">
          <button class="active nav-link processing" data-bs-toggle="tab" data-bs-target="#processing">Wymagające weryfikacji</button>
        </li>
        <li class="nav-item">
          <button class="nav-link accepted" data-bs-toggle="tab" data-bs-target="#accepted">Zaakceptowane</button>
        </li>
        <li class="nav-item">
          <button class="nav-link rejected" data-bs-toggle="tab" data-bs-target="#rejected">Odrzucone</button>
        </li>
      </ul>

      <div class="tab-content mt-5">
        <div class="tab-pane active" id="processing">
          <receipt-item v-for="r in processingList" :key="'receipt_' + r.uuid" :receipt="r" @delete-receipt="deleteReceipt"/>
        </div>
        <div class="tab-pane" id="accepted">
          <receipt-item v-for="r in acceptedList" :key="'receipt_' + r.uuid" :receipt="r"/>
        </div>
        <div class="tab-pane" id="rejected">
          <receipt-item v-for="r in rejectedList" :key="'receipt_' + r.uuid" :receipt="r"/>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row border-bottom border-dark">
        <div class="col my-5 text-center">
          <router-link v-if="$store.state.config.receipt_scanner" to="receipt-scan" class="btn btn-light">
            <span>Dodaj paragon</span>
          </router-link>
          <router-link v-else to="receipt-correction" class="btn btn-light">Dodaj paragon</router-link>
        </div>
      </div>

      <div class="row mt-5">
        <div v-if="$store.state.config.features.register_event_participation" class="col my-3">
          <tile-button label="Rejestracja udziału w wydarzeniu" icon="event" @click="eventParticipation"></tile-button>
        </div>
        <div class="col my-3">
          <tile-button label="Dalej" icon="return-user" @click="customerService"></tile-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import api from '../../../api'
import TileButton from "@/components/TileButton";
import ReceiptItem from "@/views/CustomerService/Receipt/ReceiptItem";

export default {
  name: "ReceiptList",
  components: {
    ViewHeader,
    TileButton,
    ReceiptItem
  },
  data() {
    return {
      receipts: []
    }
  },
  computed: {
    promotionalEvents() {
      return this.$store.state.config.promotionalEvents
    },
    processingList() {
      return this.receipts.filter((receipt) => receipt.status === 'processing');
    },
    acceptedList() {
      return this.receipts.filter((receipt) => receipt.status === 'accepted');
    },
    rejectedList() {
      return this.receipts.filter((receipt) => receipt.status === 'rejected');
    }
  },
  mounted() {
    this.getReceipts()
  },
  methods: {
    back() {
      this.$router.push('/customer-service')
    },
    getReceipts() {
      api.getReceiptsAll(this.$store.state.currentUser.uuid)
          .then(response => {
            this.receipts = response.data
          });
    },
    deleteReceipt(uuid) {
      api.deleteReceipt(uuid)
          .then(() => {
            this.getReceipts()
          })
    },
    eventParticipation() {
      if (this.promotionalEvents.length > 1) {
        // todo: don't exists
        // this.$router.push('/event-participate-select');

        this.$router.push('/event-participate/' + this.promotionalEvents[0].uuid);
      } else {
        this.$router.push('/event-participate/' + this.promotionalEvents[0].uuid)
      }
    },
    customerService() {
      this.$router.push('/customer-service');
    }
  }
}
</script>

<style scoped lang="scss">
.nav-tabs {
  border-bottom: none;
}

.nav-link {
  text-transform: uppercase;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 12px 20px;
  border: none;

  &.active {
    border-bottom: 3px solid $primary;
  }
}

.processing {
  color: $primary;

  &.active {
    color: $primary;
    border-bottom: 3px solid $primary;
  }
}

.accepted {
  color: $success;

  &.active {
    color: $success;
    border-bottom: 3px solid $success;
  }
}

.rejected {
  color: $danger;

  &.active {
    color: $danger;
    border-bottom: 3px solid $danger;
  }
}

</style>
