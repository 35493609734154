<template>
  <div class="authorization">
    <div class="container">
      <div class="row my-5">
        <div class="col text-center">
          <brand></brand>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col text-center">
          <div class="btn-group" role="group" aria-label="Group of buttons">
            <button type="button" :class="'btn btn-primary ' + authByQr" @click="toAuthQr">Kod QR</button>
            <button type="button" :class="'btn btn-primary ' + authByPassword" @click="toAuthPassword">Hasło</button>
          </div>
        </div>
      </div>
    </div>

    <authorization-code-scan v-if="authByQr === 'active'"></authorization-code-scan>
    <authorization-by-password v-if="authByPassword === 'active'"></authorization-by-password>

  </div>
</template>

<script>

import Brand from "@/components/Brand";
import AuthorizationCodeScan from "@/views/Auth/AuthorizationCodeScan";
import AuthorizationByPassword from "@/views/Auth/AuthorizationByPassword";

export default {
  name: 'Authorization',
  components: {
    Brand,
    AuthorizationCodeScan,
    AuthorizationByPassword
  },
  data() {
    return {
      authByPassword: '',
      authByQr: 'active',
    }
  },
  methods: {
    toAuthPassword() {
      this.authByPassword = 'active';
      this.authByQr = '';
    },
    toAuthQr() {
      this.authByPassword = '';
      this.authByQr = 'active';
    }
  }
}
</script>
