<template>
  <div class="current_user_info">
    <view-header title="Informacje o kliencie" disableBack></view-header>

    <div class="container" v-if="!editMode">
      <current-user-data @enable:edit-mode="enableEditMode()"></current-user-data>
      <reward-collected-list></reward-collected-list>

      <div class="row mt-5">
        <div v-if="$store.state.config.features.register_event_participation"  class="col my-3">
          <tile-button label="Rejestracja udziału w wydarzeniu" icon="event" @click="eventParticipation"></tile-button>
        </div>
        <div class="col my-3">
          <tile-button label="Dalej" icon="return-user" @click="customerService"></tile-button>
        </div>
      </div>

    </div>

    <div class="container" v-else>
      <current-user-data-form @disable:edit-mode="disableEditMode()"></current-user-data-form>
    </div>

  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import CurrentUserData from "@/components/CurrentUserData";
import api from "@/api";
import TileButton from "@/components/TileButton";
import RewardCollectedList from "@/components/RewardCollectedList";
import CurrentUserDataForm from "@/components/CurrentUserDataForm";

export default {
  name: "CurrentUserInfo",
  components: {
    CurrentUserData,
    CurrentUserDataForm,
    ViewHeader,
    TileButton,
    RewardCollectedList
  },
  data() {
    return {
      editMode: false,
    }
  },
  computed: {
    promotionalEvents() {
      return this.$store.state.config.promotionalEvents
    }
  },
  mounted() {
    if(this.$route.params.refresh) {
      api.getCustomerById(this.$store.state.currentUser.uuid)
        .then(response => {
          this.$store.dispatch('setCurrentUser', response.data)
        })
    }
  },
  methods: {
    eventParticipation() {
      if(this.promotionalEvents.length > 1) {
        // todo: don't exists
        // this.$router.push('/event-participate-select');

        this.$router.push('/event-participate/' + this.promotionalEvents[0].uuid);

      } else {
        this.$router.push('/event-participate/' + this.promotionalEvents[0].uuid);
      }
    },
    customerService() {
      this.$router.push('/customer-service');
    },
    enableEditMode() {
      this.editMode = true;
    },
    disableEditMode() {
      this.editMode = false;
    }
  }
}
</script>

<style scoped>

</style>
